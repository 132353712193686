html {
  font-family: Trebuchet MS, sans-serif;
}

main {
  display: flex;
}

.header {
  border-bottom: 1px solid #555;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
  display: flex;
}

.header__title, .address {
  margin: .5em 1em;
}

.coins__title, .nfts__title {
  margin: 1em 1em .5em;
}

.coins__list {
  flex-flow: wrap;
  display: flex;
}

.coin, .nft {
  box-sizing: border-box;
}

.coin {
  width: calc(100% - 2em);
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: .5em 1em;
  padding: 1em 1em 1em .7em;
  display: flex;
}

.coin__logo {
  height: 2.2em;
  aspect-ratio: 1 / 1;
  margin-right: .7em;
}

.coin-data {
  flex-grow: 1;
}

.coin-data__name {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  overflow: hidden;
}

.coin-value {
  text-align: right;
}

.coin-data__fiat-value, .coin-value__fiat {
  color: #555;
  font-size: .9em;
}

.nfts__list {
  flex-flow: wrap;
  display: flex;
}

.nft {
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: .5em 1em 2em;
  overflow: hidden;
}

.nft__image {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
}

.nft__data {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: .5em 1em .2em;
  font-weight: 600;
  overflow: hidden;
}

.nft__collection {
  margin: 0 1em 1em;
  font-size: .9em;
  display: flex;
}

.nft__chain-logo {
  height: 1em;
  aspect-ratio: 1 / 1;
  margin-right: .3em;
}

.nft-collection__name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

main {
  flex-direction: column;
}

.nft {
  width: calc(100% - 2em);
}

@media (min-width: 600px) {
  .header {
    flex-direction: row;
  }

  .nft {
    width: calc(50% - 2em);
  }
}

@media (min-width: 800px) {
  .coin {
    width: calc(50% - 2em);
  }
}

@media (min-width: 960px) {
  .nft {
    width: calc(33% - 2em);
  }
}

@media (min-width: 1280px) {
  main {
    flex-direction: row;
  }

  .coins__title, .nfts__title {
    margin: .5em 1em;
  }

  .coins__list {
    width: 500px;
    flex-direction: column;
  }

  .coin {
    width: calc(100% - 2em);
  }

  .nfts {
    width: calc(100% - 500px);
  }

  .nft {
    max-width: 512px;
  }
}

/*# sourceMappingURL=index.68cfa786.css.map */
