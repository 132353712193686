html { font-family: "Trebuchet MS", sans-serif; }
main { display: flex; }
.header { margin-bottom: 1em; border-bottom: 1px solid #555; display: flex; flex-direction: column; align-items: center; }
.header__title { margin: .5em 1em; }
.address { margin: .5em 1em; }
.coins__title, .nfts__title { margin: 1em 1em .5em 1em; }
.coins__list { display: flex; flex-direction: row; flex-flow: wrap; }
.coin, .nft { box-sizing: border-box; }
.coin { margin: .5em 1em; width: calc(100% - 2em); padding: 1em 1em 1em .7em; border: 1px solid #ddd; border-radius: 10px; display: flex; }
.coin__logo { margin-right: .7em; height: 2.2em; aspect-ratio: 1/1; }
.coin-data { flex-grow: 1; }
.coin-data__name { font-weight: 600; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.coin-value { text-align: right; }
.coin-data__fiat-value, .coin-value__fiat { font-size: .9em; color: #555; }
.nfts__list { display: flex; flex-direction: row; flex-wrap: wrap; }
.nft { margin: .5em 1em 2em; border: 1px solid #ddd; border-radius: 10px; overflow: hidden; }
.nft__image { width: 100%; aspect-ratio: 1/1; object-fit: contain; }
.nft__data { margin: .5em 1em .2em; font-weight: 600; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.nft__collection { margin: 0 1em 1em; display: flex; font-size: .9em; }
.nft__chain-logo { margin-right: .3em; height: 1em; aspect-ratio: 1/1; }
.nft-collection__name { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

main { flex-direction: column; }
.nft { width: calc(100% - 2em); }

@media (min-width: 600px) {
    .header { flex-direction: row; }
    .nft { width: calc(50% - 2em); }
}

@media (min-width: 800px) {
    .coin { width: calc(50% - 2em); }
}

@media (min-width: 960px) {
    .nft { width: calc(33% - 2em); }
}

@media (min-width: 1280px) {
    main { flex-direction: row; }
    .coins__title, .nfts__title { margin: .5em 1em .5em 1em; }
    .coins__list { width: 500px; flex-direction: column; }
    .coin { width: calc(100% - 2em); }
    .nfts { width: calc(100% - 500px); }
    .nft { max-width: 512px; }
}
